import Service from './Service';

class EdrSqlLogService extends Service {
    constructor() {
        super();
    }
    async get(pageParam) {
        return this.callApiGet(`/edrSqlLog${pageParam}`);
    }
    async create(postData) {
        return this.callApiPost(`/edrSqlLog`, postData);
    }
    async update(postData) {
        return this.callApiPut(`/edrSqlLog`, postData);
    }
    async delete(id) {
        return this.callApiDelete(`/edrSqlLog?_ids=${id}`);
    }
}
export default EdrSqlLogService