<template>
  <v-container
    id="page-edrSqlLog"
    fluid
  >

    <base-wee-sketlon-loader
      :loading="state.loading"
      type="table-heading, table-thead, table-tbody, table-tfoot"
      :no="1"
    />

    <!-- Table  -->
    <wee-simple-table
      v-if="!state.loading"
      :headers="fillableHeaders"
      :title="$t('model.edr_sql_log.edr_sql_log')"
      :tr-list="filteredList"
      :pages="pages"
      :sort="sort"
      @update-search="searchTxt = $event"
      @on-item-click="onItemClick"
      @on-item-delete="onBeforeDeleteItem"
      @on-open-new-form="onOpenNewForm"
      @on-advance-search="advanceSearch"
      @on-reload-page="onReload"
    >
      <!-- <template v-slot:theader></template> 
      <template v-slot:tbody></template> 
      <template v-slot:tpaging></template>  -->
    </wee-simple-table>

    <edr-sql-log-form v-model="entity" :edit-mode="editMode" :open="openNewForm" :processing="isProcessing" @close="openNewForm = false" @save="onSave"/>
    <wee-confirm ref="weeConfirmRef"></wee-confirm>
    <wee-toast ref="weeToastRef"></wee-toast>
  </v-container>
</template>

<script>
import { vLog } from "@/plugins/util";
//service
import EdrSqlLogService from "@/api/EdrSqlLogService";
import useCrudApi from "@/composition/UseCrudApi";
import { toRefs, onBeforeUnmount} from "@vue/composition-api";
export default {
  name: "page-edrSqlLog",
  components: {
    WeeConfirm: () => import("@/components/WeeConfirm"),
    WeeToast: () => import("@/components/WeeToast"),
    WeeSimpleTable: () => import("@/components/WeeSimpleTable"),
    EdrSqlLogForm: () => import("./EdrSqlLogForm"),
  },
  setup(props, { refs, root }) {
    const edrSqlLogService = new EdrSqlLogService();
//column, label, searchable, sortable, fillable, image, avatar status, date, datetime 
    const tableHeaders = [
      {
        column: "sql_logs",
        label: "model.edr_sql_log.sql_logs",
        searchable: true,
        sortable: true,
        fillable: true,
        //linkable: {external: true},
      },
      {
        column: "created_username",
        label: "model.edr_sql_log.created_user",
        searchable: true,
        sortable: true,
        fillable: true,
        //linkable: {external: true},
      },
      {
        column: "created_at",
        label: "model.edr_sql_log.created_at",
        searchable: true,
        sortable: true,
        fillable: true,
        //linkable: {external: true},
      },
      {
        label: "base.tool",
        fillable: true,
        baseTool: true
      }
    ];

    //entity
    const initialItem = {
      id: 0,
      sql_logs: '',
      created_user: 0,
      created_at: '',
      updated_user: 0,
      updated_at: '',
    };

    const {
      crud
    } = useCrudApi(refs, root, edrSqlLogService, initialItem, tableHeaders);

    //fell free to change sort colunm and mode
    crud.sort.column = "id";
    crud.sort.mode = "DESC";

    onBeforeUnmount(()=>{
      vLog('onBeforeUnmount')
    });

    return {
      ...toRefs(crud),
    };
  }
};
</script>
